/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom font styles */
.cairo-play-light {
  font-family: "Cairo Play", sans-serif;
  font-weight: 300;
}

.cairo-play-regular {
  font-family: "Cairo Play", sans-serif;
  font-weight: 400;
}

.cairo-play-bold {
  font-family: "Cairo Play", sans-serif;
  font-weight: 700;
}

/* Custom font styles for Cairo Play */
.cairo-play {
  font-family: "Cairo Play", sans-serif;
  font-weight: 400;
}

/* Custom font styles for Kode Mono */
.kode-mono-light {
  font-family: "Kode Mono", monospace;
  font-weight: 400;
}

.kode-mono-bold {
  font-family: "Kode Mono", monospace;
  font-weight: 700;
}

.courier-prime-regular {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

.courier-prime-bold {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: normal;
}

.courier-prime-regular-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: italic;
}

.courier-prime-bold-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: italic;
}

/* Apply Cairo Play to all p tags */
p,
li {
  font-family: "Cairo Play", sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

body {
  @apply bg-bodyBg text-bodyText font-sans;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dark body {
  @apply bg-darkBodyBg text-darkBodyText;
}

/* Custom scrollbar styles for the main content */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #1a202c; /* Darker background for dark mode */
}

body::-webkit-scrollbar-thumb {
  background-color: #718096; /* Darker thumb color */
  border-radius: 6px;
  border: 3px solid #1a202c; /* Match the background color */
}

.container {
  max-width: 1200px;
  padding: 0 1rem;
  margin: auto;
}

main > div {
  /* margin-bottom: 4rem; */
}

footer {
  background-color: #2c5282; /* Tailwind blue-800 */
  @apply bg-blue-800 dark:bg-blue-900 text-white py-3;
  width: 100%;
  flex-shrink: 0;
}

@keyframes modal-open {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal-close {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: auto;
  max-width: 80%;
  max-width: 1200px; /* This should match the max-width of the container */
  max-height: 90%;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
  z-index: 1050; /* Ensure the modal is above other elements */
}

.dark .modal {
  @apply bg-darkModalBg; /* Use the dark mode background color for modal */
}

.modal-open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.modal-close {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1040; /* Ensure the overlay is below the modal but above other elements */
}

/* Custom scrollbar styles */
.modal::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar-track {
  background: #1a202c; /* Darker background for dark mode */
}

.modal::-webkit-scrollbar-thumb {
  background-color: #718096; /* Darker thumb color */
  border-radius: 6px;
  border: 3px solid #1a202c; /* Match the background color */
}

.preview-modal {
  width: 80%; /* Adjusted width to be consistent with the container */
  max-width: 1200px; /* Adjusted max-width to be consistent with the container */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 0;
}

.dark .close-button {
  color: white;
}
